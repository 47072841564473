import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import type { FormRenderProps } from 'react-final-form';
import type { FormApi, FormState } from 'final-form';
import { FORM_ERROR } from 'final-form';
import { Grid } from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import moment from 'moment';
import { OnChange } from 'react-final-form-listeners';
import {
  BeneficiaryFormLabels,
  BeneficiariesPageTexts,
  BeneficiaryModalDOBHelperText,
} from '../../containers/beneficiaries/beneficiaries.constants';
import type {
  Beneficiary,
  PatchBeneficiary,
  BeneficiaryName,
  CreateBeneficiary,
  UpdateBeneficiaryAddressRequest,
  UpdateBeneficiaryRequest,
} from '../../containers/beneficiaries/beneficiaries.constants';
import type { Account, BeneficiaryForm, Dispatch, CustomerInfo } from '../../utilities/types';
import type { List } from '../../reducers/lists.reducer';
import FormModal from './FormModal';
import { RenderCheck } from '../finalForm/finalFormInputs';
import { Header3, SectionHeader, BodyTextMedium } from '../typography/typography';
import { buildAccountNameWithProductDots } from '../../formatters/buildAccountName';
import { useBeneficiaryModalStyles } from './BeneficiaryModal.styles';
import { PersonalInformationFormLabels } from '../cms/personalInformation.constants';
import {
  AddressFieldNames,
  BeneficiaryFieldNames,
  IdentifyingInformationFieldNames,
} from '../../form.constants';
import { ModalText } from '../cms/blockText.constants';
import { ModalBtnText } from '../cms/buttonText.constants';
import {
  StreetAddress1,
  StreetAddress2,
  City,
  State,
  ZipCode,
} from '../openNewAccountFormFlow/addressFields';
import {
  FirstName,
  LastName,
  MiddleName,
} from '../openNewAccountFormFlow/personalInformationFields';
import { DobInformation } from '../openNewAccountFormFlow/dobInformation';
import {
  addOrRemoveBeneficiary,
  createBeneficiaryInfo,
  updateBeneficiaryInfo,
} from '../../containers/beneficiaries/beneficiaries.service';
import {
  checkEmptyDOB,
  checkIfDobIsFutureDate,
  checkIfOutOfRange,
  checkValidDOB,
} from '../../containers/newAccountOpening/newUser/openAccount.validators';
import { composeValidators } from '../../utilities/validators';
import trimSuperfluousSpaces from '../../formatters/trimSuperfluousSpaces';
import {
  ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_SUCCESS,
  ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_FAILURE,
} from '../../analytics/actions';
import pageTrack from '../../analytics/pageAnalytics.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';
import { handleAccountChange, handleAddressChange } from './BeneficiaryModalHelpers';

type Props = {
  accounts: Account[];
  beneficiary: Beneficiary | null | undefined;
  onCancel: (shouldRefetchData: boolean) => void;
  states: List;
  visible: boolean;
  isAddMode: boolean;
  onSuccess: () => Promise<void>;
  updateFormDirtyStatus: (arg1: boolean) => void;
  primaryOwnerInfo: CustomerInfo;
};

const pageViewActions = Object.freeze({
  submitSuccess: ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_SUCCESS,
  submitFailure: ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_FAILURE,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];

type DispatchProps = {
  recordAnalyticsPageView: (pageViewAction: PageViewAction, payload?: string) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  recordAnalyticsPageView: (pageViewAction: PageViewAction, payload?: string) =>
    dispatch({ type: pageViewAction, payload }),
});

type AllProps = Props & DispatchProps;

export const hasUpdatedBeneficiaryAddress = ({ modified = {} }: FormState<BeneficiaryForm>) =>
  modified[AddressFieldNames.STREET_ADDRESS_1] ||
  modified[AddressFieldNames.STREET_ADDRESS_2] ||
  modified[AddressFieldNames.CITY] ||
  modified[AddressFieldNames.STATE] ||
  modified[AddressFieldNames.ZIP_CODE];

export const hasUpdatedBeneficiaryAccounts = (
  accounts: Account[],
  { modified = {} }: FormState<BeneficiaryForm>
) => accounts.some((account) => modified[account.accountId]);

export const BeneficiaryModal = (props: AllProps) => {
  const {
    accounts,
    beneficiary,
    onCancel,
    states,
    visible,
    isAddMode,
    onSuccess,
    recordAnalyticsPageView,
    updateFormDirtyStatus,
    primaryOwnerInfo,
  } = props;
  const classes = useBeneficiaryModalStyles();

  const [customerBeneficiaryId, setCustomerBeneficiaryId] = useState<string>('');

  const [beneficiaryInfoError, setBeneficiaryInfoError] = useState<string | null | undefined>();
  const [beneficiaryAccountsError, setBeneficiaryAccountsError] = useState<
    string | null | undefined
  >();

  // List of account IDs
  const [accountsWithErrors, setAccountsWithErrors] = useState<string[] | null | undefined>();
  const [showActiveState, setShowActiveState] = useState<boolean>(false);

  if (!isAddMode && !beneficiary) return null;

  // Returns false if request is made and returns an error, true otherwise
  const handleUpdateBeneficiaryInfo = async (
    beneficiaryId: string,
    beneficiaryInfo?: UpdateBeneficiaryRequest
  ) => {
    // beneficiaryInfo is only defined if there were updates (and the request should be made)
    if (!beneficiaryInfo) return true;
    try {
      await updateBeneficiaryInfo(beneficiaryId, beneficiaryInfo);
    } catch (err) {
      setBeneficiaryInfoError(
        (err && err.data && err.data.message) || BeneficiariesPageTexts.BENEFICIARY_INFO_ERROR
      );
      return false;
    }
    setBeneficiaryInfoError(undefined);
    return true;
  };

  // Returns false if request is made and returns an error, true otherwise
  const handleUpdateBeneficiaryAccounts = async (request?: PatchBeneficiary) => {
    // request is only defined if there were updates (and the request should be made)
    if (!request) return true;
    try {
      const response = await addOrRemoveBeneficiary(request);
      const failedAccounts = response.relationship.accounts
        .filter((account) => parseInt(account.status, 10) >= 400)
        .map((account) => account.accountId);

      if (failedAccounts.length) {
        setAccountsWithErrors(failedAccounts);
        throw new Error();
      }
    } catch {
      setBeneficiaryAccountsError(BeneficiariesPageTexts.BENEFICIARY_ACCOUNTS_ERROR);
      return false;
    }
    setBeneficiaryAccountsError(undefined);
    return true;
  };

  const accountData = (values: BeneficiaryForm) => {
    return accounts.map((account) => ({
      accountId: account.accountId,
      accountType: account.accountType,
      action: values[account.accountId] ? 'Add' : 'Delete',
    }));
  };

  const onSubmitAddBeneficiary = async (values: BeneficiaryForm) => {
    const addresses = [];
    const beneficiaryName: BeneficiaryName = {
      first: trimSuperfluousSpaces(values[IdentifyingInformationFieldNames.FIRST_NAME]) || '',
      middle: trimSuperfluousSpaces(values[IdentifyingInformationFieldNames.MIDDLE_NAME]) || '',
      last: trimSuperfluousSpaces(values[IdentifyingInformationFieldNames.LAST_NAME]) || '',
    };
    const beneficiaryAddress: UpdateBeneficiaryAddressRequest = {
      line1: trimSuperfluousSpaces(values[AddressFieldNames.STREET_ADDRESS_1]) || '',
      line2: trimSuperfluousSpaces(values[AddressFieldNames.STREET_ADDRESS_2]) || '',
      city: trimSuperfluousSpaces(values[AddressFieldNames.CITY]) || '',
      state: values[AddressFieldNames.STATE],
      postalCode: values[AddressFieldNames.ZIP_CODE],
      type: 'Primary',
    };
    addresses.push(beneficiaryAddress);
    const createBeneficiaryRequest: UpdateBeneficiaryRequest = {
      name: beneficiaryName,
      dateOfBirth: values[IdentifyingInformationFieldNames.DOB],
      addresses,
    };

    let createBeneficiaryResponse: CreateBeneficiary;
    // call create Beneficiary end point to create new Beneficiary
    let customerId;

    const handleBeneficiaryError = (err, errorMessage) => {
      recordAnalyticsPageView(pageViewActions.submitFailure, pageTrack.pagesubfunction.ADD);
      const errorText = (err && err.data && err.data.message) || errorMessage;
      return {
        [FORM_ERROR]: '',
        errorText,
      };
    };

    const handleBeneficiaryInfoError = (err) => {
      const errorMessage = BeneficiariesPageTexts.CREATE_BENEFICIARY_ERROR;
      setBeneficiaryInfoError(errorMessage);
      return handleBeneficiaryError(err, errorMessage);
    };

    const handleBeneficiaryAccountsError = (err) => {
      const errorMessage = BeneficiariesPageTexts.UPDATE_BENEICIARY_ACCOUNTS_ERROR;
      setBeneficiaryAccountsError(errorMessage);
      return handleBeneficiaryError(err, errorMessage);
    };

    if (!customerBeneficiaryId) {
      try {
        createBeneficiaryResponse = await createBeneficiaryInfo(createBeneficiaryRequest);
        customerId = createBeneficiaryResponse?.beneficiaryId;
        setCustomerBeneficiaryId(customerId);
        setBeneficiaryInfoError(undefined);
      } catch (err) {
        handleBeneficiaryInfoError(err);
        return { [FORM_ERROR]: '' };
      }
    }

    const patchAccountsRequest = {
      relationship: {
        beneficiaryId: customerId || customerBeneficiaryId,
        accounts: accountData(values),
      },
    } as const;

    // call the update Beneficiary relationship end point to update accounts to the beneficiary added
    try {
      const response = await addOrRemoveBeneficiary(patchAccountsRequest);
      const failedAccounts = response.relationship.accounts
        .filter((account) => parseInt(account.status, 10) >= 400)
        .map((account) => account.accountId);

      if (failedAccounts.length) {
        setAccountsWithErrors(failedAccounts);
        throw new Error();
      }
    } catch (err) {
      handleBeneficiaryAccountsError(err);
      return { [FORM_ERROR]: '' };
    }
    setBeneficiaryAccountsError(undefined);
    recordAnalyticsPageView(pageViewActions.submitSuccess, pageTrack.pagesubfunction.ADD);
    return onSuccess();
  };

  const onSubmitEditBeneficiary = async (
    values: BeneficiaryForm,
    form: FormApi<BeneficiaryForm>
  ) => {
    if (!beneficiary) {
      return undefined;
    }
    const updatedAddressFormValues: UpdateBeneficiaryAddressRequest = {
      line1: trimSuperfluousSpaces(values[AddressFieldNames.STREET_ADDRESS_1]) || '',
      line2: trimSuperfluousSpaces(values[AddressFieldNames.STREET_ADDRESS_2]) || '',
      city: trimSuperfluousSpaces(values[AddressFieldNames.CITY]) || '',
      state: values[AddressFieldNames.STATE],
      postalCode: values[AddressFieldNames.ZIP_CODE],
      type: 'Primary',
    };

    // to check if the beneficiary info is updated or not
    let updateBeneficiaryInfoRequest: UpdateBeneficiaryRequest;
    const hasUpdatedAddress = hasUpdatedBeneficiaryAddress(form.getState());
    if (hasUpdatedAddress) {
      const addresses = [];
      addresses.push(updatedAddressFormValues);
      updateBeneficiaryInfoRequest = {
        name: beneficiary.name,
        dateOfBirth: beneficiary.dateOfBirth,
        addresses,
      };
    }

    // to check if new beneficiary accounts have been added or removed from the initial account list
    const hasUpdatedAccounts = hasUpdatedBeneficiaryAccounts(accounts, form.getState());
    let patchAccountsRequest;
    if (hasUpdatedAccounts) {
      patchAccountsRequest = {
        relationship: {
          beneficiaryId: beneficiary.beneficiaryId,
          accounts: accountData(values),
        },
      };
    }

    // call the endpoints async, handling errors separately
    const results = await Promise.all([
      handleUpdateBeneficiaryInfo(beneficiary.beneficiaryId, updateBeneficiaryInfoRequest),
      handleUpdateBeneficiaryAccounts(patchAccountsRequest),
    ]);

    // A result of true means either the request was successful or it wasn't made at all
    if (results.filter(Boolean).length !== 2) {
      recordAnalyticsPageView(pageViewActions.submitFailure, pageTrack.pagesubfunction.EDIT);
      return { [FORM_ERROR]: '' };
    }

    recordAnalyticsPageView(pageViewActions.submitSuccess, pageTrack.pagesubfunction.EDIT);
    onSuccess();
    return undefined;
  };

  // If there is a partial error, refetch the beneficiaries data if the modal is closed
  const handleCancel = () => {
    onCancel(!!(beneficiaryInfoError || beneficiaryAccountsError));
  };

  const renderAccountCheckboxLabel = (account: Account) => (
    <>
      {buildAccountNameWithProductDots(account)}
      {!!accountsWithErrors && accountsWithErrors.includes(account.accountId) && (
        <SVGImage imageName={ImagesFileNames.iconErrorSvg} className={classes.errorIcon} />
      )}
    </>
  );

  return (
    <Form onSubmit={isAddMode ? onSubmitAddBeneficiary : onSubmitEditBeneficiary}>
      {({ form, handleSubmit, pristine, submitting, values }: FormRenderProps<BeneficiaryForm>) => {
        const defaultInputProps = {
          disabled: submitting,
          useFinalForm: true,
        } as const;
        updateFormDirtyStatus(!pristine);
        const isAtLeastOneAccountSelected = accounts.some((account) => values[account.accountId]);
        const dobValidator = composeValidators(
          checkEmptyDOB,
          checkValidDOB,
          checkIfDobIsFutureDate,
          checkIfOutOfRange
        );

        const handleFormSubmit = () => {
          setShowActiveState(false);
          const errorElement: HTMLElement = document.querySelector(
            `[name='${Object.keys(form?.getState()?.errors)[0]}']`
          );
          if (errorElement) {
            errorElement?.focus();
            setShowActiveState(true);
          }

          handleSubmit();
        };

        const isCheckBoxChecked = (_: boolean, valueAll: Record<string, string>) => {
          return accounts.some((account) => Object.keys(valueAll).includes(account.accountId))
            ? undefined
            : 'Select at least one beneficiary account.';
        };

        return (
          <FormModal
            visible={visible}
            titleText={
              isAddMode
                ? ModalText.ADD_BENEFICIARY_MODAL_TITLE
                : ModalText.EDIT_BENEFICIARY_MODAL_TITLE
            }
            confirmText={isAddMode ? ModalBtnText.ADD_BENEFICIARY : ModalBtnText.SAVE_CHANGES}
            onCancel={handleCancel}
            cancelDataTrackTitle={clickTrack.beneficiaries.cancel_changes}
            data-track-type={clickTrackType.BUTTON}
            cancelDataTrackPage={clickTrack.beneficiaries.view}
            onEscapeKeyDown={handleCancel}
            handleSubmit={handleFormSubmit}
            classes={{ paper: classes.paper }}
            onLoading={submitting}
          >
            <Prompt when={!pristine} message={ModalText.CANCEL_ALERT_MODAL_CONTENT} />
            <Grid container spacing={4}>
              {isAddMode && beneficiaryInfoError && !submitting && (
                <Grid
                  item
                  xs={12}
                  className={classes.beneficiaryInfoError}
                  role="status"
                  data-test="beneficiary-info-error-container"
                >
                  {beneficiaryInfoError}
                </Grid>
              )}
              <Grid container item spacing={1} xs={12} sm={7} alignContent="flex-start">
                {isAddMode ? (
                  <Grid container item xs={12} sm={12} md={12} spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FirstName {...defaultInputProps} dataTest="beneficiary-first-name" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MiddleName {...defaultInputProps} dataTest="beneficiary-middle-name" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LastName {...defaultInputProps} dataTest="beneficiary-last-name" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DobInformation
                        {...defaultInputProps}
                        validate={dobValidator}
                        dataTest="beneficiary-dob"
                      />
                      <span className={classes.dobFieldInstruction}>
                        {BeneficiaryModalDOBHelperText}
                      </span>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid container item xs={12} spacing={3} component="dl">
                      <Grid item xs={12} sm={6}>
                        <SectionHeader component="dt" className={classes.infoLabel}>
                          {PersonalInformationFormLabels.FIRST_NAME}
                        </SectionHeader>
                        <BodyTextMedium component="dd" textTransform="capitalize">
                          {beneficiary && beneficiary.name.first.toLowerCase()}
                        </BodyTextMedium>
                      </Grid>
                      {beneficiary && beneficiary.name.middle && (
                        <Grid item xs={12} sm={6}>
                          <SectionHeader component="dt" className={classes.infoLabel}>
                            {PersonalInformationFormLabels.MIDDLE_NAME}
                          </SectionHeader>
                          <BodyTextMedium component="dd" textTransform="capitalize">
                            {beneficiary && beneficiary.name.middle.toLowerCase()}
                          </BodyTextMedium>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <SectionHeader component="dt" className={classes.infoLabel}>
                          {PersonalInformationFormLabels.LAST_NAME}
                        </SectionHeader>
                        <BodyTextMedium component="dd" textTransform="capitalize">
                          {beneficiary && beneficiary.name.last.toLowerCase()}
                        </BodyTextMedium>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SectionHeader component="dt" className={classes.infoLabel}>
                          {PersonalInformationFormLabels.DATE_OF_BIRTH}
                        </SectionHeader>
                        <BodyTextMedium component="dd">
                          {beneficiary && moment(beneficiary.dateOfBirth).format('L')}
                        </BodyTextMedium>
                      </Grid>
                    </Grid>
                    {beneficiaryInfoError && !submitting && (
                      <Grid
                        item
                        xs={12}
                        className={classes.beneficiaryInfoError}
                        role="status"
                        data-test="edit-beneficiary-info-error-container"
                      >
                        {beneficiaryInfoError}
                      </Grid>
                    )}
                  </>
                )}

                <Grid
                  item
                  xs={12}
                  className={
                    isAddMode
                      ? classes.sameAddressCheckboxAddMode
                      : classes.sameAddressCheckboxEditMode
                  }
                >
                  <Field
                    name={AddressFieldNames.SAME_ADDRESS}
                    label={BeneficiaryFormLabels.SAME_AS_PRIMARY_ADDRESS}
                    labelId={`${AddressFieldNames.SAME_ADDRESS}LabelId`}
                    component={RenderCheck}
                    type="checkbox"
                    disabled={submitting}
                  />
                  <OnChange name={AddressFieldNames.SAME_ADDRESS}>
                    {(value: boolean) =>
                      handleAddressChange({ value, primaryOwnerInfo, form, isAddMode, beneficiary })
                    }
                  </OnChange>
                </Grid>

                <Grid item xs={12} md={8}>
                  <StreetAddress1
                    {...defaultInputProps}
                    disabled={!!values[AddressFieldNames.SAME_ADDRESS]}
                    initialValue={beneficiary?.addresses[0]?.line1 || ''}
                    dataTest="beneficiary-street-address"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StreetAddress2
                    {...defaultInputProps}
                    disabled={!!values[AddressFieldNames.SAME_ADDRESS]}
                    initialValue={beneficiary?.addresses[0]?.line2 || ''}
                    dataTest="beneficiary-unit-number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <City
                    {...defaultInputProps}
                    disabled={!!values[AddressFieldNames.SAME_ADDRESS]}
                    initialValue={beneficiary?.addresses[0]?.city || ''}
                    dataTest="beneficiary-city"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <State
                    {...defaultInputProps}
                    disabled={!!values[AddressFieldNames.SAME_ADDRESS]}
                    initialValue={beneficiary?.addresses[0]?.state || ''}
                    dataTest="beneficiary-state"
                    states={states}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ZipCode
                    {...defaultInputProps}
                    disabled={!!values[AddressFieldNames.SAME_ADDRESS]}
                    initialValue={beneficiary?.addresses[0]?.postalCode || ''}
                    dataTest="beneficiary-zip"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={5}
                alignContent="flex-start"
                role="group"
                aria-labelledby="add_accounts"
              >
                <Grid id="add_accounts" item xs={12} component={Header3}>
                  {BeneficiariesPageTexts.ADD_TO_ACCOUNTS}
                </Grid>
                <Grid item xs={12} className={classes.selectAccountsDescription}>
                  {BeneficiariesPageTexts.SELECT_ACCOUNTS}
                </Grid>
                {beneficiaryAccountsError && !submitting && (
                  <Grid
                    item
                    xs={12}
                    className={classes.beneficiaryAccountsError}
                    role="status"
                    data-test="beneficiary-accounts-error-container"
                  >
                    {beneficiaryAccountsError}
                  </Grid>
                )}
                {beneficiary && !isAtLeastOneAccountSelected && (
                  <Grid item xs={12} className={classes.beneficiaryAccountsError} role="status">
                    {BeneficiariesPageTexts.PLEASE_SELECT_ACCOUNT}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Field
                    name={BeneficiaryFieldNames.ALL_ACCOUNTS}
                    id={BeneficiaryFieldNames.ALL_ACCOUNTS}
                    component={RenderCheck}
                    validate={isCheckBoxChecked}
                    type="checkbox"
                    label={BeneficiaryFormLabels.ALL_ACCOUNTS}
                    initialValue={accounts.length === beneficiary?.accounts.length}
                    disabled={submitting}
                    inputClassName={classes.inputCheckbox}
                    showActiveState={showActiveState}
                  />
                  <OnChange name={BeneficiaryFieldNames.ALL_ACCOUNTS}>
                    {(value?: boolean | string) => {
                      // For some reason calling form.change with undefined can result in the value being '' here
                      if (typeof value !== 'undefined' && value !== '') {
                        form.batch(() => {
                          accounts.forEach((account) => {
                            form.change(account.accountId, value);
                          });
                        });
                      }
                    }}
                  </OnChange>
                </Grid>

                {accounts.map((account) => (
                  <Grid item xs={12} key={account.accountId}>
                    <Field
                      name={account.accountId}
                      id={account.accountId}
                      component={RenderCheck}
                      type="checkbox"
                      label={renderAccountCheckboxLabel(account)}
                      initialValue={
                        beneficiary &&
                        beneficiary.accounts.some(
                          (beneficiaryAccount) => beneficiaryAccount.accountId === account.accountId
                        )
                      }
                      disabled={submitting}
                      inputClassName={classes.inputCheckbox}
                      showActiveState={showActiveState}
                    />
                    <OnChange name={account.accountId}>
                      {(value: boolean) => {
                        handleAccountChange({ value, accounts, values, account, form });
                      }}
                    </OnChange>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </FormModal>
        );
      }}
    </Form>
  );
};

export default connect(null, mapDispatchToProps)(BeneficiaryModal);
